import classNames from 'classnames'
import { DropdownSelect } from 'components/forms/dropdowns/dropdownSelect'
import { Switch } from 'components/forms/switch'
import { Modal } from 'components/modal/modal'
import { ComponentWithLink, Link } from 'components/ui/links/link'
import { LogoImage } from 'components/ui/logoImage'
import { Skeleton } from 'components/ui/skeleton'
import { TextWithReadMore } from 'components/ui/textWithReadMore'
import { TooltipOnHover } from 'components/ui/tooltip'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, REFRESH_INTERVAL_MS, useProfileMetricsWithMinMaxExtended } from 'data/queries'
import { useState } from 'react'
import { getMetricPercentage, getMetricValueByKey } from 'utils/actions'
import { ALL_KEY, DEFAULT_ASSET_TIMEFRAME, TIMEFRAME_CHOICES, TYPE_ASSET } from 'utils/constants'
import { formatOutputNumber, isInvalidNumber } from 'utils/formatter'

import {
    checkInverseMetricOrder,
    getFormattedMetricValue,
    getMarketPosition,
    getPercentageTextColorClassNames,
    getSimilarPerformers,
    getUniquePerformers,
    getValueOrPercentage,
    getValueTextColorClassNames,
    isGrayScaleMetric,
} from './utils'

const PerformerRow = ({
    performer = null,
    metricGroup = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    isCurrent = null,
    byChange = false,
}) => {
    const value = getMetricValueByKey(performer, metricGroup?.key, metricGroup?.timeframeKey)

    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? getMetricPercentage(
                  performer,
                  metricGroup?.key,
                  timeframeKey,
                  metricGroup?.prefix || metricGroup?.postfix
              )
            : null

    return (
        <Link href={`/asset/${performer?.slug}`} blank={false}>
            <div
                className={`grid grid-rows-1 grid-cols-2 w-full gap-x-4 py-2 border-b border-solid ${
                    isCurrent ? 'border-primary' : 'border-contrast-2/20'
                } hover:bg-contrast-1`}
            >
                <div className='flex flex-row items-center gap-x-1 min-w-[60px] max-w-[60px]'>
                    <LogoImage
                        src={performer?.logoUrl}
                        alt={performer?.name}
                        size={16}
                    />
                    <p className='text-xs font-bold text-contrast-3'>
                        {performer?.symbol}
                    </p>
                </div>
                {!byChange ? (
                    <div className='text-xs font-bold text-contrast-4'>
                        {getFormattedMetricValue(value, metricGroup)}
                    </div>
                ) : (
                    !isInvalidNumber(percentage) && (
                        <div className='text-xs font-bold text-contrast-3'>
                            {formatOutputNumber(percentage, {
                                precision: 2,
                                forcePrecision: false,
                                allowEmpty: false,
                                withAbbreviation: true,
                                showApproximation: true,
                                postfix: '%',
                                spaceAfterNumber: false,
                                showPlus: true,
                            })}
                        </div>
                    )
                )}
            </div>
        </Link>
    )
}

const MarketPositionItem = ({
    item = null,
    value = null,
    withTooltip = false,
    isCurrent = false,
    isAboveImage = false,
}) => {
    if (!item) return

    if (withTooltip) {
        return (
            <TooltipOnHover
                text={
                    <>
                        <p className='font-bold'>{item?.symbol}</p>
                        <p>{value}</p>
                    </>
                }
                textClassName='max-w-[100px] !text-center'
            >
                <Link href={`/asset/${item?.slug}`} blank={false}>
                    <div className='flex flex-col justify-center gap-y-1'>
                        <LogoImage
                            className='self-center'
                            src={item?.logoUrl}
                            alt={item?.name}
                            size={24}
                        />
                    </div>
                </Link>
            </TooltipOnHover>
        )
    }

    return (
        <Link href={`/asset/${item?.slug}`} blank={false}>
            <div
                className={`flex ${isAboveImage ? 'flex-col-reverse -top-[36px]' : 'flex-col'} gap-y-1 justify-center`}
            >
                <LogoImage className='self-center' src={item?.logoUrl} alt={item?.name} size={24} />
                <div className='max-w-[100px] !text-center'>
                    <p className={`font-bold text-[10px] ${isCurrent ? 'text-primary' : 'text-contrast-4'}`}>
                        {item?.symbol}
                    </p>
                    <p className={`font-bold text-[10px] ${isCurrent ? 'text-primary' : 'text-contrast-3'}`}>{value}</p>
                </div>
            </div>
        </Link>
    )
}

const MarketPositionsScale = ({
    marketPositionItems = [],
    metricGroup = null,
    byChange = false,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    isTablet = false,
    slug = '',
}) => {
    const isInversePerformersOrder = checkInverseMetricOrder(metricGroup?.key)

    const minValueIndex = isInversePerformersOrder ? marketPositionItems?.length - 1 : 0
    const maxValueIndex = isInversePerformersOrder ? 0 : marketPositionItems?.length - 1

    const minMarketValue = getValueOrPercentage(
        marketPositionItems?.[minValueIndex],
        byChange,
        metricGroup,
        timeframeKey
    )
    const maxMarketValue = getValueOrPercentage(
        marketPositionItems?.[maxValueIndex],
        byChange,
        metricGroup,
        timeframeKey
    )

    return (
        <div className={`relative ${isTablet ? 'ml-12 mr-4' : 'ml-6'} mb-12`}>
            <div
                className={`bg-gradient-to-r ${
                    isGrayScaleMetric(metricGroup?.key)
                        ? 'from-shade-lightest via-shade-light to-shade-darkest'
                        : 'from-[var(--c-red)] via-[var(--c-yellow)] to-[var(--c-green)]'
                } w-full h-[2px] rounded`}
            />
            {marketPositionItems.map((pItem, idx) => {
                const unformattedValue = getValueOrPercentage(pItem, byChange, metricGroup, timeframeKey)
                const marketPosition = getMarketPosition(
                    metricGroup?.key,
                    unformattedValue,
                    minMarketValue,
                    maxMarketValue
                )
                let formattedValue = ''
                if (!isInvalidNumber(unformattedValue)) {
                    if (metricGroup?.withPercentage && byChange) {
                        formattedValue = formatOutputNumber(unformattedValue, {
                            precision: 2,
                            forcePrecision: false,
                            allowEmpty: false,
                            withAbbreviation: true,
                            showApproximation: true,
                            postfix: '%',
                            spaceAfterNumber: false,
                            showPlus: true,
                        })
                    } else {
                        formattedValue = getFormattedMetricValue(unformattedValue, metricGroup)
                    }
                }

                return (
                    <div
                        key={`position-${pItem?.slug}-${idx}`}
                        className={`absolute -top-[12px]`}
                        style={{
                            left: `calc(${marketPosition * 100}% - 24px)`,
                        }}
                    >
                        <MarketPositionItem
                            item={pItem}
                            value={formattedValue}
                            withTooltip={isTablet}
                            isCurrent={pItem?.slug === slug}
                            isAboveImage={pItem?.slug === slug && marketPositionItems?.length > 2}
                        />
                    </div>
                )
            })}
        </div>
    )
}

const ExtendedInformation = ({
    metricGroup = null,
    isTablet = false,
    timeframe = DEFAULT_ASSET_TIMEFRAME,
    setTimeframe = () => {},
    byChange = false,
    setByChange = () => {},
}) => {
    return (
        <div className={`flex gap-y-6 ${isTablet ? 'flex-col' : 'flex-col-reverse'}`}>
            {metricGroup?.withPercentage && (
                <div className='flex flex-row items-center justify-between gap-x-2'>
                    <DropdownSelect
                        className='z-10 w-[84px]'
                        choices={TIMEFRAME_CHOICES}
                        selected={timeframe}
                        onSelect={setTimeframe}
                        small
                        renderSelected={selected => (
                            <div className='flex flex-row items-center justify-between w-full py-1 cursor-pointer gap-x-2'>
                                <span className='icon icon-clock min-w-[12px] max-w-[12px] min-h-[12px] max-h-[12px] !bg-contrast-4' />
                                <div className='overflow-auto text-xs font-bold leading-none truncate whitespace-normal text-contrast-4'>
                                    {selected?.name}
                                </div>
                            </div>
                        )}
                    />
                    <Switch
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            setByChange(!byChange)
                        }}
                        isLeft={!byChange}
                        labelRight='By Change %'
                        textClassName={`text-${
                            byChange ? 'primary' : 'contrast-4'
                        } font-normal text-[10px] whitespace-nowrap`}
                    />
                </div>
            )}
            <TextWithReadMore text={metricGroup?.tooltipTextObj?.text ?? ''} />
        </div>
    )
}

export const ExpandedMetric = ({
    metricGroup = null,
    onClose = () => {},
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    isSortableInArchive = false,
    item = null,
    isTablet = false,
}) => {
    const [timeframe, setTimeframe] = useState(
        TIMEFRAME_CHOICES.find(choice => choice.key === timeframeKey) ?? DEFAULT_ASSET_TIMEFRAME
    )
    const [byChange, setByChange] = useState(false)

    const value = getMetricValueByKey(item, metricGroup?.key, metricGroup?.timeframeKey)
    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? getMetricPercentage(item, metricGroup?.key, timeframe?.key, metricGroup?.prefix || metricGroup?.postfix)
            : null

    const isInversePerformersOrder = checkInverseMetricOrder(metricGroup?.key)

    const { data, isLoading } = useProfileMetricsWithMinMaxExtended(
        TYPE_ASSET,
        metricGroup,
        item,
        metricGroup?.key === 'price' ? REFRESH_INTERVAL_ASSET_PRICE_MS : REFRESH_INTERVAL_MS,
        timeframe?.key,
        metricGroup?.postfix === '%',
        byChange
    )

    const withComparison = metricGroup?.key !== 'net_staking_flow'

    let topPerformers = []
    let worstPerformers = []
    let similarPerformers = []
    if (!isLoading && data) {
        topPerformers = data?.[`${isInversePerformersOrder ? 'min' : 'max'}_${metricGroup?.key}`] ?? []
        worstPerformers = data?.[`${isInversePerformersOrder ? 'max' : 'min'}_${metricGroup?.key}`] ?? []
        similarPerformers = withComparison
            ? getSimilarPerformers(
                  item,
                  metricGroup,
                  [...(data?.[`lt_${metricGroup?.key}`] ?? []), ...(data?.[`gte_${metricGroup?.key}`] ?? [])].filter(
                      p => p?.slug !== item?.slug
                  ),
                  byChange,
                  timeframe?.key
              )
            : []
    }

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(percentage, metricGroup?.withColor)

    return (
        <Modal className={`${isTablet ? '!pl-6 !pr-12 !pb-6 !pt-16' : '!px-0 !py-6'}`} isOpen onClose={onClose}>
            <div className={`flex ${isTablet ? 'flex-row' : 'flex-col'} w-full justify-between`}>
                <div
                    className={`flex flex-col px-6 ${
                        isTablet ? 'border-r border-solid border-contrast-2/20 min-w-[240px] max-w-[240px]' : 'mb-4'
                    }`}
                >
                    <div className='mb-3 font-bold text-md text-contrast-4'>
                        {metricGroup?.label ?? 'Metric'}
                    </div>
                    <div className='flex flex-row items-center mb-3 gap-x-1'>
                        <LogoImage
                            src={item?.logoUrl}
                            alt={item?.name}
                            size={16}
                        />
                        <p className='text-xs font-bold text-contrast-3'>
                            {item?.name}
                        </p>
                    </div>
                    <div className='flex flex-row items-center p-5 mb-3 rounded-lg gap-x-2 bg-contrast-1'>
                        <p
                            className={classNames(
                                'text-md font-bold mr-2',
                                valueColorClassName
                            )}
                        >
                            {getFormattedMetricValue(value, metricGroup)}
                        </p>
                        {!isInvalidNumber(percentage) && (
                            <TooltipOnHover
                                text={`${timeframe?.key} ${
                                    metricGroup?.postfix === '%' ? 'Absolute Change' : 'Change Percentage'
                                }`}
                                textClassName={'!text-[10px] !whitespace-normal !text-center !w-[80px] !px-2 !py-1'}
                            >
                                <p className={classNames('text-xs font-bold', percentageColorClassName)}>
                                    {formatOutputNumber(percentage, {
                                        precision: 2,
                                        forcePrecision: false,
                                        allowEmpty: false,
                                        withAbbreviation: true,
                                        showApproximation: true,
                                        postfix: '%',
                                        spaceAfterNumber: false,
                                        showPlus: true,
                                    })}
                                </p>
                            </TooltipOnHover>
                        )}
                    </div>
                    {isTablet && (
                        <ExtendedInformation
                            metricGroup={metricGroup}
                            isTablet={isTablet}
                            timeframe={timeframe}
                            setTimeframe={setTimeframe}
                            byChange={byChange}
                            setByChange={setByChange}
                        />
                    )}
                </div>
                <div className={isTablet ? 'overflow-hidden' : 'overflow-x-hidden overflow-y-auto mr-6'}>
                    <div className={`flex flex-col w-full ${isTablet ? '' : 'h-[400px]'} gap-y-5 px-6`}>
                        {!isTablet && (
                            <ExtendedInformation
                                metricGroup={metricGroup}
                                isTablet={isTablet}
                                timeframe={timeframe}
                                setTimeframe={setTimeframe}
                                byChange={byChange}
                                setByChange={setByChange}
                            />
                        )}
                        <Performers
                            slug={item?.slug}
                            topPerformers={topPerformers}
                            similarPerformers={similarPerformers}
                            worstPerformers={worstPerformers}
                            byChange={byChange}
                            isLoading={isLoading}
                            isInversePerformersOrder={isInversePerformersOrder}
                            isSortableInArchive={isSortableInArchive}
                            timeframeKey={timeframe?.key}
                            withComparison={withComparison}
                            metricGroup={metricGroup}
                            isTablet={isTablet}
                        />
                        <div className='flex flex-col w-full mb-3'>
                            <div
                                className={`font-bold text-md text-contrast-4 ${isTablet ? 'pl-6 mb-8' : 'pl-0 mb-12'}`}
                            >
                                Market Position
                            </div>
                            {isLoading ? (
                                <Skeleton width={'100%'} height={'64px'} borderRadius={8} />
                            ) : (
                                <MarketPositionsScale
                                    marketPositionItems={getUniquePerformers(
                                        [
                                            worstPerformers?.[0],
                                            ...(isTablet ? similarPerformers : [item]),
                                            topPerformers?.[0],
                                        ],
                                        item,
                                        metricGroup,
                                        byChange,
                                        timeframe?.key,
                                        isInversePerformersOrder ? 'desc' : 'asc'
                                    )}
                                    metricGroup={metricGroup}
                                    byChange={byChange}
                                    timeframeKey={timeframe?.key}
                                    isTablet={isTablet}
                                    slug={item?.slug}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const Performers = ({
    slug = '',
    topPerformers = [],
    similarPerformers = [],
    worstPerformers = [],
    byChange = false,
    timeframeKey = '',
    isLoading = false,
    metricGroup = null,
    withComparison = true,
    isInversePerformersOrder = false,
    isSortableInArchive = true,
    isTablet = false,
}) => {
    const getLinkToArchive = (isTop = true) => {
        if (!isSortableInArchive) return null

        const order = (!isInversePerformersOrder && isTop) || (isInversePerformersOrder && !isTop) ? 'desc' : 'asc'
        return `/assets/${ALL_KEY}?sort=${metricGroup?.key}&timeframe=${timeframeKey}&order=${order}&byChange=${
            byChange ? 'true' : 'false'
        }`
    }

    return (
        <div className={`${isTablet ? 'overflow-x-auto overflow-y-hidden' : ''} pb-6`}>
            <div className={`flex ${isTablet ? 'flex-row' : 'flex-col gap-y-6'}`}>
                <div
                    className={`flex flex-col ${
                        isTablet ? 'px-6 border-r border-solid border-contrast-2/20 min-w-[280px]' : 'px-0'
                    }`}
                >
                    <ComponentWithLink link={getLinkToArchive(true)} blank={false}>
                        <div
                            className={`font-bold text-md text-contrast-4 mb-3 ${
                                getLinkToArchive(true) ? 'hover:underline' : ''
                            }`}
                        >
                            {isGrayScaleMetric(metricGroup?.key)
                                ? isInversePerformersOrder
                                    ? 'Lowest'
                                    : 'Highest'
                                : 'Top Performers'}
                        </div>
                    </ComponentWithLink>
                    {isLoading ? (
                        <Skeleton width='100%' height='165px' borderRadius={8} />
                    ) : (
                        <div className='flex flex-col'>
                            {topPerformers?.map((performer, idx) => (
                                <PerformerRow
                                    key={`performer-${performer?.slug}-${idx}`}
                                    performer={performer}
                                    metricGroup={metricGroup}
                                    timeframeKey={timeframeKey}
                                    isCurrent={performer?.slug === slug}
                                    byChange={byChange}
                                />
                            ))}
                        </div>
                    )}
                </div>
                {withComparison && (
                    <div
                        className={`flex flex-col ${
                            isTablet ? 'px-6 border-r border-solid border-contrast-2/20 min-w-[280px]' : 'px-0'
                        }`}
                    >
                        <div className='mb-3 font-bold text-md text-contrast-4'>
                            {isGrayScaleMetric(metricGroup?.key)
                                ? 'Similar'
                                : 'Similar Performers'}
                        </div>
                        {isLoading ? (
                            <Skeleton width='100%' height='165px' borderRadius={8} />
                        ) : (
                            <div className='flex flex-col'>
                                {similarPerformers?.map((performer, idx) => (
                                    <PerformerRow
                                        key={`performer-${performer?.slug}-${idx}`}
                                        performer={performer}
                                        metricGroup={metricGroup}
                                        timeframeKey={timeframeKey}
                                        isCurrent={performer?.slug === slug}
                                        byChange={byChange}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <div className={`flex flex-col ${isTablet ? 'px-6 min-w-[280px]' : 'px-0'}`}>
                    <ComponentWithLink link={getLinkToArchive(false)} blank={false}>
                        <div
                            className={`font-bold text-md text-contrast-4 mb-3 ${
                                getLinkToArchive(false) ? 'hover:underline' : ''
                            }`}
                        >
                            {isGrayScaleMetric(metricGroup?.key)
                                ? isInversePerformersOrder
                                    ? 'Highest'
                                    : 'Lowest'
                                : 'Worst Performers'}
                        </div>
                    </ComponentWithLink>
                    {isLoading ? (
                        <Skeleton width='100%' height='165px' borderRadius={8} />
                    ) : (
                        <div className='flex flex-col'>
                            {worstPerformers?.map((performer, idx) => (
                                <PerformerRow
                                    key={`performer-${performer?.slug}-${idx}`}
                                    performer={performer}
                                    metricGroup={metricGroup}
                                    timeframeKey={timeframeKey}
                                    isCurrent={performer?.slug === slug}
                                    byChange={byChange}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
